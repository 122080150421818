<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">PROBATION </span> LIST
    </PageHeader>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-btn @click="generateProbation" color="primary">
              Generate Probation List
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="loadProbation">
      <v-col>
        <v-data-table
          :headers="headers"
          hide-default-footer
          :items="basketWithIndex"
          disable-pagination
          class="elevation-1"
          pagination.sync="pagination"
          item-key="id"
        >
          <template v-slot:item.cwa="{ item }">
            {{ parseFloat(item.cwa).toFixed(2) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-overlay :absolute="absolute" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <information v-if="infoResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </information>

    <Error v-if="deleteResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Error>
  </v-container>
</template>
<script>
  import PageHeader from "@/components/slots/PageHeader";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import { computed, getCurrentInstance, reactive, toRefs } from "vue";
  import Error from "../../components/Error/Error.vue";
  import Information from "../../components/Error/Information.vue";
  export default {
    components: { PageHeader, Information, Error },
    setup() {
      const vm = getCurrentInstance()
      const { getProbationStudent, signOut } = useActions([
        "getProbationStudent",
        "signOut",
      ]);
      const { getters_probation } = useGetters(["getters_probation"]);

      const probationTable = reactive({
        deleteResponse: false,
        infoResponse: false,
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        loadProbation: false,
        absolute: true,
        overlay: false,
        headers: [
          { text: "NO.", value: "index" },
          { text: "INDEX NUMBER", value: "index_number" },
          { text: "NAME OF STUDENT", value: "name" },
          { text: "CUMULATED WEIGHTED AVERAGE", value: "cwa", align: "center" },
        ],
      });
      const {
        deleteResponse,
        loadProbation,
        overlay,
        infoResponse,
        msgHeader,
        msgBody,
        msgIcon,
      } = toRefs(probationTable);

      const generateProbation = () => {
        infoResponse.value = false;
        overlay.value = true;
        loadProbation.value = false;
        deleteResponse.value = false;
        getProbationStudent()
          .then(() => {
            loadProbation.value = true;
            overlay.value = false;
          })
          .catch((err) => {
            overlay.value = false;
            if (err.response.status === 422) {
              infoResponse.value = true;
              msgHeader.value = "Information";
              msgIcon.value = "mdi-information-outline";
              msgBody.value = err.response.data.message;
            } else if (err.response.status === 403) {
              deleteResponse.value = true;
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              msgBody.value = err.response.data.message;
            }
            if (err.response.status === 423) {
              msgHeader.value = "Error";
              msgIcon.value = "mdi-close-circle";
              deleteResponse.value = true;
              msgBody.value = err.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            }
          });
      };

      const basketWithIndex = computed(() => {
        return getters_probation.value.map((item, index) => ({
          ...item,
          index: index + 1,
        }));
      });

      return {
        ...toRefs(probationTable),
        generateProbation,
        basketWithIndex,
      };
    },
  };
</script>