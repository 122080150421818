<template>
  <v-snackbar
    top
    absolute
    transition="slide-y-transition"
    multi-line
    color="info"
    v-model="deleteResponse"
  >
    <v-row align="center" justify="center">
      <v-col cols="2" class="d-flex justify-center">
        <v-icon x-large> <slot name="icon"></slot> </v-icon>
      </v-col>
      <v-col>
        <div class="title">
          <strong> <slot name="header"></slot></strong>
        </div>
        <div class="body"><slot name="body"></slot></div>
      </v-col>
    </v-row>
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="deleteResponse = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { defineComponent, /* inject, */ ref } from "vue";

  export default defineComponent({
    setup() {
      let deleteResponse = ref(true);

      return { deleteResponse };
    },
  });
</script>
